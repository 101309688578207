@media screen and (min-width: 1200px) {
    .dashboard {
        > .row {
            height: calc(100% - var(--bs-gutter-y));
            overflow: hidden;

            > div {
                padding: 10px;
            }
        }

        .card {
            height: 100%;
        }
    }
}

.first-mb > :first-child {
    margin-bottom: 18px;
}