.selected-layout {
    height: 44px;
    width: 44px;
    z-index: 1;
    background: black;

    svg {
        width: 100%;
        height: 100%;
    }
}

.layout-options {
    position: absolute;
    left: 105%; 
    transition: left 200ms ease-in-out;

    &.active {
        left: calc(100% - 423px);
        transition: left 200ms ease-in-out;
    }

    li {
        width: 44px;
        height: 44px;
        overflow: hidden;
        a:hover {
            background: #DB7100;
            border-radius: 4px;
        }
    }

    a {
        svg {
            display: block; 
            width: 100%;
            height: 100%;
        }
    }
}

.draggingListItem {
    background: 'rgb(235,235,235)'
}

.listGray {
    background-color: #565555;
}