//
// Theme style
//

@tailwind base;
@tailwind components;
@tailwind utilities;

// Initialize
@import 'init';

@import '../../assets/fonts/segoe-ui-webfont/style.css';

// Components
@import './core/components/components';
@import 'components/components';

// Layout
@import './core/layout/base';
@import 'layout/layout';
