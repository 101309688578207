.chart-text {
  fill: #fff;
  -moz-transform: translateY(0.6em);
  -ms-transform: translateY(0.6em);
  -webkit-transform: translateY(0.6em);
  transform: translateY(0.6em);
}

.chart-number {
  font-size: 1rem;
  line-height: 1;
  text-anchor: middle;
  -moz-transform: translateY(-0.50em);
  -ms-transform: translateY(-0.50em);
  -webkit-transform: translateY(-0.25em);
  transform: translateY(-0.25em);
}

.riskChartContainer {
  height: 100%
}

.figure-key-list li {
  margin: 0 0 8px;
  padding: 0;
  color: white;
  display: flex;
  justify-content: space-between;
}

.shape-square {
  display: inline-block;
  vertical-align: middle;
  width: 13px;
  height: 13px;
}

  